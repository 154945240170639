let services = document.querySelectorAll('.service-option');
let subtotalEl = document.querySelector('#sub-total');
let vatEl = document.querySelector('#vat');
let totalEl = document.querySelector('#total');
let subtotalEl2 = document.querySelector('#sub-total02');
let vatEl2 = document.querySelector('#vat02');
let totalEl2 = document.querySelector('#total02');
let acceptedAmount = document.querySelector('#accepted-amount');
let totalAmount = document.querySelector('#total-amount');
let proceedWithQuote = document.querySelector('#proceed-with-quote');
let acceptQuote = document.querySelector('#save-quote');
let vatVal;

if(vatEl) {
    vatVal = vatEl.dataset.vatRate;
}

if(services) {
    services.forEach(service => {
        let input = service.querySelector('input[name="service-selection"]');
        if(input.checked) {
            calcPrice(parseFloat(input.dataset.price));
            acceptQuote.dataset.selectedService = input.dataset.serviceName;
        }
        service.addEventListener('click', function() {
            calcPrice(parseFloat(input.dataset.price));
            acceptQuote.dataset.selectedService = input.dataset.serviceName;
        });
    });
}


function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

function calcPrice(priceVal) {
    let price = priceVal;
    let vatAddOn = price * parseFloat(vatVal);
    let grandTotal = price + parseFloat(vatAddOn);
    subtotalEl.innerHTML = `£${numberWithCommas(price.toFixed(2))}`;
    vatEl.innerHTML = `£${numberWithCommas(vatAddOn.toFixed(2))}`;
    totalEl.innerHTML = `£${numberWithCommas(grandTotal.toFixed(2))}`;
    subtotalEl2.innerHTML = `£${numberWithCommas(price.toFixed(2))}`;
    vatEl2.innerHTML = `£${numberWithCommas(vatAddOn.toFixed(2))}`;
    totalEl2.innerHTML = `£${numberWithCommas(grandTotal.toFixed(2))}`;
    acceptedAmount.value = `£${numberWithCommas(grandTotal.toFixed(2))}`;
    proceedWithQuote.disabled = false;
    totalAmount.value = grandTotal.toFixed(2);
}
