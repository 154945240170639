import $ from 'jquery';
import swal from 'sweetalert';
$(document).foundation();

let quoteConsent = document.querySelector('#quote-terms-consent');
let StepOneButton = document.querySelector('#proceed-with-quote');
let StepTwoButton = document.querySelector('#procceed-step-two');
let stepOne = document.querySelector('#quote-step-one');
let stepTwo = document.querySelector('#quote-step-two');
let services = document.querySelectorAll('.service-option');
let ableToProcced = false;
let amountEntered = false;


if(StepOneButton) {
    
    StepOneButton.addEventListener('click', () => {
        let amount = document.querySelector('#accepted-amount').value;
        services.forEach(service => {
            let input = service.querySelector('input[name="service-selection"]');
            if(input.checked ) {
                ableToProcced = true;
            }
        });
        console.log(amount);
        if(amount) {
            amountEntered = true;
        } else {
            amountEntered = false;
        }
        if( ableToProcced !== true) {
            //alert('Please choose a service before proceeding');
            swal({
                title: "Please choose a service before proceeding",
                text: "Before you can proceed you need to select one fo the services from you quote.",
                icon: "error",
              });
        } else if(amountEntered !== true) {
            swal({
                title: "Form Error",
                text: "An amount has not been selected. If you have selected a service and this error persists please get in touch with us.",
                icon: "error",
              });
        } else {
            $('#quote-terms').foundation('open');
        }
    });
}

// if(quoteConsent) {
//     quoteConsent.addEventListener('change', () => {
//         if(quoteConsent.checked) {
//             StepTwoButton.disabled = false;
//         } else {
//             StepTwoButton.disabled = true;
//         }
//     });
// }

if(StepTwoButton) {
    StepTwoButton.addEventListener('click', () => {
        if(quoteConsent.checked) {
            stepOne.style.display = "none";
            stepTwo.style.display = "block";
            setTimeout(()=>{
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 100)
        } else {
            //alert('Please agree to the Term & Conditions before proceeding');
            swal({
                title: "Please agree to the Term & Conditions before proceeding",
                text: "You can not proceed with the quote until you have clicked the 'I confirm that I understand and agree the terms and conditions outlined above.' box at the end of the Terms and Conditions popup located just above the next button.",
                icon: "error",
              });
        }
        
    });
}

// let parent = document.querySelector('.quote-sidebar').parentElement;

// while (parent) {
//     const hasOverflow = getComputedStyle(parent).overflow;
//     if(hasOverflow !== 'visible') {
//         console.log(hasOverflow, parent);
//     }
//     parent = parent.parentElement;
// }