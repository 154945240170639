const postcodeSingle = document.querySelector('#postcode-lookup');
const addressSelect = document.querySelector('#address-list');
const addressDropdown = document.querySelector('#address-dropdown');
const addressFill = document.querySelector('#address');
const loadingIcon = document.querySelector('#address-loading');
const postcodeKey = 'XH58-ED78-RN98-AJ99';

if(postcodeSingle) {
    const postcodeButton = postcodeSingle.querySelector('#postcode-lookup-button');
    const postcodeField = postcodeSingle.querySelector('#postcode-lookup-field');
    
    
    postcodeButton.addEventListener('click', function() {
        postcodeLookup(postcodeKey,postcodeField.value,false,'','','GBR','','en','','');
    });

    addressSelection(addressSelect);
}

function postcodeLookup(key,text,IsMiddleware,Container,Origin,Countries,Limit,Language,Bias,Filters) {
    loadingIcon.style.display = 'inline-block';
    let params = '';

        params += "&Key=" + encodeURIComponent(key);

        params += "&Text=" + encodeURIComponent(text);

        params += "&IsMiddleware=" + encodeURIComponent(IsMiddleware);

        params += "&Container=" + encodeURIComponent(Container);

        params += "&Origin=" + encodeURIComponent(Origin);

        params += "&Countries=" + encodeURIComponent(Countries);

        params += "&Limit=" + encodeURIComponent(Limit);

        params += "&Language=" + encodeURIComponent(Language);

        params += "&Bias=" + encodeURIComponent(Bias);

        params += "&Filters=" + encodeURIComponent(Filters);

        fetch(`https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws?${params}`)
            .then(
                function(response) {
                    loadingIcon.style.display = 'none';
                    console.log(response);
                    if (response.status !== 200) {
                        console.log('Looks like there was a problem. Status Code: ' + response.status);
                        return;
                    }
                    response.json().then(function(data) {
                        console.log(data);
                        if (data.Items.length == 1 && typeof(data.Items[0].Error) != "undefined") {

                            // Show the error message
                
                            console.log(data.Items[0].Description);
                            addressDropdown.style.display = 'none';
                
                        } else if (data.Items.length == 0) {

                            // Show the error message
                
                            console.log("Sorry, there were no results");
                            addressDropdown.style.display = 'none';
                
                        } else {
                            if(data.Items[0].Type === 'Postcode') {
                                addressLookup(postcodeKey,'',false,data.Items[0].Id,'','GBR','','en','','');
                            } else {
                                addressPopulateSelect(data.Items, addressSelect);
                                addressDropdown.style.display = 'block';
                            }

                        }

                
                    });

                }
            )
            .catch(
                function(err) {
                    console.log('Fetch Error', err);
                }
            );
}

function addressLookup(key,text,IsMiddleware,Container,Origin,Countries,Limit,Language,Bias,Filters) {

    loadingIcon.style.display = 'inline-block';

    let params = '';

        params += "&Key=" + encodeURIComponent(key);

        params += "&Text=" + encodeURIComponent(text);

        params += "&IsMiddleware=" + encodeURIComponent(IsMiddleware);

        params += "&Container=" + encodeURIComponent(Container);

        params += "&Origin=" + encodeURIComponent(Origin);

        params += "&Countries=" + encodeURIComponent(Countries);

        params += "&Limit=" + encodeURIComponent(Limit);

        params += "&Language=" + encodeURIComponent(Language);

        params += "&Bias=" + encodeURIComponent(Bias);

        params += "&Filters=" + encodeURIComponent(Filters);

        fetch(`https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws?${params}`)
            .then(
                function(response) {
                    loadingIcon.style.display = 'none';
                    if (response.status !== 200) {
                        console.log('Looks like there was a problem. Status Code: ' + response.status);
                        return;
                    }
                    response.json().then(function(data) {
                        console.log(data);
                       
                        if (data.Items.length == 1 && typeof(data.Items[0].Error) != "undefined") {

                            // Show the error message
                
                            console.log(data.Items[0].Description);
                            addressDropdown.style.display = 'block';
                
                        } else if (data.Items.length == 0) {

                            // Show the error message
                
                            console.log("Sorry, there were no results");
                            addressDropdown.style.display = 'block';
                
                        } else {
                            if(data.Items[0].Type === 'Address') {
                                addressPopulateSelect(data.Items, addressSelect);
                                addressDropdown.style.display = 'block';
                            } else {
                                console.log("Sorry, something is wrong with the address lookup as the date returned is not correct");
                                console.log(data.Items);
                            }

                        }

                
                    });

                }
            )
            .catch(
                function(err) {
                    console.log('Fetch Error', err);
                }
            );
}

function addressPopulateSelect(addresses, selectEl) {
    addresses.forEach(address =>{
        let opt = document.createElement('option');
        opt.value = address.Id;
        opt.innerHTML = address.Text;
        selectEl.appendChild(opt);
    });
}

function addressSelection(selectEl) {
    selectEl.addEventListener('change', ()=>{
        let lookupID = selectEl.options[selectEl.selectedIndex].value;
        let params = '';
        let billing1 = document.querySelector('#billing-1');
        let billing2 = document.querySelector('#billing-2');
        let billingCity = document.querySelector('#billing-city');
        let billingPostcode = document.querySelector('#billing-postcode');
        let wpbilling1 = document.querySelector('#address1');
        let wpbilling2 = document.querySelector('#address2');
        let wpbillingCity = document.querySelector('#town');
        let wpbillingPostcode = document.querySelector('#postcode');

        //clear the address values
        billing1.value = '';
        billing2.value = '';
        billingCity.value = '';
        billingPostcode.value = '';
        wpbilling1.value = '';
        wpbilling2.value = '';
        wpbillingCity.value = '';
        wpbillingPostcode.value = '';

        params += "&Key=" + encodeURIComponent(postcodeKey);

        params += "&Id=" + encodeURIComponent(lookupID);

        fetch(`https://api.addressy.com/Capture/Interactive/Retrieve/v1.00/json3.ws?${params}&Field1Format={{City}}&Field2Format=&Field3Format=&Field4Format=&Field5Format=&Field6Format=&Field7Format=&Field8Format=&Field9Format=&Field10Format=&Field11Format=&Field12Format=&Field13Format=&Field14Format=&Field15Format=&Field16Format=&Field17Format=&Field18Format=&Field19Format=&Field20Format=`)
            .then(
                function(response) {
                    if (response.status !== 200) {
                        console.log('Looks like there was a problem. Status Code: ' + response.status);
                        return;
                    }
                    response.json().then(function(data) {
                        console.log(data);
                       
                        if (data.Items.length == 1 && typeof(data.Items[0].Error) != "undefined") {

                            // Show the error message
                
                            console.log(data.Items[0].Description);
                            addressFill.style.display = 'none';
                
                        } else if (data.Items.length == 0) {

                            // Show the error message
                
                            console.log("Sorry, there were no results");
                            addressFill.style.display = 'none';
                
                        } else {
                            // Populate the address fields
                            if(data.Items[0].Company !== "") {
                                billing1.value = data.Items[0].Company;
                                billing2.value = data.Items[0].Line1;
                                wpbilling1.value = data.Items[0].Company;
                                wpbilling2.value = data.Items[0].Line1;
                            } else {
                                billing1.value = data.Items[0].Line1;
                                wpbilling1.value = data.Items[0].Line1;
                            }
                            billingCity.value = data.Items[0].City;
                            billingPostcode.value = data.Items[0].PostalCode;
                            wpbillingCity.value = data.Items[0].City;
                            wpbillingPostcode.value = data.Items[0].PostalCode;

                            addressFill.style.display = 'block';
                        }

                
                    });

                }
            )
            .catch(
                function(err) {
                    console.log('Fetch Error', err);
                }
            );
    });
}