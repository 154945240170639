function printDiv(divId) {
    // var printContents = document.getElementById(divId).innerHTML;
    // var originalContents = document.body.innerHTML;
  
    // document.body.innerHTML = printContents;
  
    // window.print();
  
    // document.body.innerHTML = originalContents;

    var mywindow = window.open('', 'new div', 'height=600,width=800');
    mywindow.document.write('<html><head><title></title>');
    mywindow.document.write('<link rel="stylesheet" href="/wp-content/themes/tiles/assets/styles/style.css" type="text/css" media="all">');
    mywindow.document.write('</head><body>');
    mywindow.document.write(document.getElementById(divId).innerHTML);
    mywindow.document.write('</body></html>');

    mywindow.document.close();
    mywindow.focus();
    setTimeout(function(){
        mywindow.print(); 
        mywindow.close();
    },1000);

    return true;
}
var printButton = document.querySelector('#print-quote');
if(printButton) {
    printButton.addEventListener('click', ()=>{
        printDiv('quote-print-area');
    });
}